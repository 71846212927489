<template>
    <div class="form-container">

        <v-form v-model="isValid">
            
            <vertical-form-table v-model="form" v-bind="{ items }">                        
                <template slot="유형">
                    <v-radio-group v-model="form.type" row hide-details>
                        <v-radio label="기업" value="기업"></v-radio>
                        <v-radio label="개인" value="개인"></v-radio>
                    </v-radio-group>
                </template>                        
                <template slot="구입시기">
                    <v-row align="center" class="row--xs">
                        <v-col cols="6" md="">
                            <v-select v-model="year" :items="years" placeholder="년도" outlined hide-details :disabled="ispurchase"/>
                        </v-col>
                        <v-col cols="6" md="">
                            <v-select v-model="month" :items="months" placeholder="월" outlined hide-details :disabled="ispurchase"/>
                        </v-col>
                        <v-col cols="12" md="auto" class="mt-4px mt-md-0">
                            <v-checkbox v-model="ispurchase" label="구입 시기 잘 모름" hide-details />
                        </v-col>
                    </v-row>
                </template>                        
                <template slot="구입한 대리점">
                    <v-row align="center" class="row--xs">
                        <v-col cols="6" md="">
                            <v-select v-model="city" :items="cities" placeholder="시/도" outlined hide-details :disabled="isStore"/>
                        </v-col>
                        <v-col cols="6" md="">
                            <v-select v-model="district" :items="districts[city]" placeholder="구/군" outlined hide-details :disabled="isStore"/>
                        </v-col>
                        <v-col cols="12" md="">
                            <v-select placeholder="대리점을 선택하세요." outlined hide-details :disabled="isStore"/>
                        </v-col>
                        <v-col cols="12" md="auto" class="mt-4px mt-md-0">
                            <v-checkbox v-model="isStore" label="구입 대리점 잘 모름" hide-details />
                        </v-col>
                    </v-row>
                </template>
            </vertical-form-table>

            <div class="mt-20px mt-md-40px">
                <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
            </div>

            <div class="btn-wrap">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <btn-primary v-bind="{ disabled }" @click="save">문의하기</btn-primary>
                    </v-col>
                </v-row>
            </div>
        </v-form>

    </div>
</template>

<script>
// import { initUser, rules } from "@/assets/variables";

import { cities, districts } from "@/assets/data/city"

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";

import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import api from "@/api";

const items = [
    {
        key: "type",
        term: "유형",
        required: true,
    },
    {
        key: "company",
        term: "회사명",
        type: "text",
        required: true,
        placeholder: "회사명을 입력해주세요.",
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        placeholder: "이름을 입력해주세요.",
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        placeholder: "이메일을 입력하세요.",
        // rules: rules.email,
    },
    {
        key: "phone",
        term: "연락처",
        type: "text",
        required: true,
        placeholder: "‘-’를 제외하고 입력하세요.",
    },
    {
        key: "address",
        term: "주소",
        type: "address",
        required: true,
        placeholder: "",
    },
    {
        key: "purchasedAt",
        term: "구입시기",
        required: true,
    },
    {
        key: "store",
        term: "구입한 대리점",
        required: true,
    },
    {
        key: "symptom",
        term: "하자 증상",
        type: "text",
        placeholder: "하자 증상을 선택하세요.",
    },
    {
        key: "modelNo",
        term: "제품 모델 번호",
        type: "text",
        placeholder: "제품 모델 번호를 입력하세요.",
    },
    {
        key: "content",
        term: "요청내용",
        type: "textarea",
        required: true,
        placeholder: "A/S 요청 제품의 상태를 구체적으로 적어주시면 정확한 상담이 가능합니다.",
    },
    {
        key: "file",
        term: "첨부파일",
        type: "file",
        placeholder: "파일을 첨부하세요.",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,

        BtnPrimary,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            work: null,
            form: this.$props.value,

            // form: initUser(),
            items,
            isValid: false,
            isTermsValid: undefined,

            city: "서울특별시",
            district: null,
            store: null,
            ispurchase: false,

            year: null,
            month: null,
            isStore: false,

            years: [
                { text: "2023", value: "2023" },
                { text: "2022", value: "2022" },
                { text: "2021", value: "2021" },
                { text: "2020", value: "2020" },
                { text: "2019", value: "2019" },
                { text: "2018", value: "2018" },
                { text: "2017", value: "2017" },
                { text: "2016", value: "2016" },

            ],
            months: [
                { text: "1", value: "01" },
                { text: "2", value: "02" },
                { text: "3", value: "03" },
                { text: "4", value: "04" },
                { text: "5", value: "05" },
                { text: "6", value: "06" },
                { text: "7", value: "07" },
                { text: "8", value: "08" },
                { text: "9", value: "09" },
                { text: "10", value: "10" },
                { text: "11", value: "11" },
                { text: "12", value: "12" },
            ],

            cities,
            districts,
        };
    },
    computed: {
        _work() {
            return this.$route.query._work;
        },

        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    // mounted() {
    //     this.init();
    // },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        async init() {
            const { _work: _id } = this;
            const { work } = await api.v1.auction.works.get({ _id });
            this.work = work;
        },
        save() {
            if (this.isStore === true) {
                this.form.store = true;
            } else {
                this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
            }

            if (this.ispurchase === true) {
                this.form.purchasedAt = true;
            } else {
                this.form.purchasedAt = `${this.year || ""}-${this.month || ""}`
            }

            this.$emit("save", this.form)
        },

        // validat() {
        //     try {
        //         if (!this.form.type) throw new Error("유형을 선택해주세요.");
        //         if (!this.form.company) throw new Error("회사명을 입력해주세요.");
        //         if (!this.form.name) throw new Error("이름을 입력해주세요.");
        //         if (!this.form.email) throw new Error("이메일을 입력해주세요.");
        //         if (!this.form.phone) throw new Error("연락처를 입력해주세요.");
        //         if (!this.form.zipcode) throw new Error("주소를 입력해주세요.");
        //         if (!this.form.address1) throw new Error("주소를 입력해주세요.");
        //         if (!this.form.address2) throw new Error("주소를 입력해주세요.");
        //         return true;
        //     } catch(error) {
        //         alert(error.message)
        //         return false;
        //     }
        // },

        // save() {
        //     if (this.validat()) {
        //         this.$emit('save')
        //     }
        // },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },

    watch: {
        "form.address"() {
            this.form.zipcode = this.form.address.postcode;
            this.form.address1 = this.form.address.address1;
            this.form.address2 = this.form.address.address2;
        },

        city() {
            this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
        },

        district() {
            this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
        }, 
        store() {
            this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
        },
        isStore() {
            if (this.isStore === true) {
                this.form.store = true;
            } else {
                this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
            }
        },

        "form.store"() {
            this.form.store = `${this.city || ""} / ${this.district || ""} / ${this.store || ""}`
        },

        "form.purchasedAt"() {
            this.form.purchasedAt = `${this.year || ""}-${this.month || ""}`
        },

        year() {
            this.form.purchasedAt = `${this.year || ""}-${this.month || ""}`
        },
        month() {
            this.form.purchasedAt = `${this.year || ""}-${this.month || ""}`
        },
        ispurchase() {
            if (this.ispurchase === true) {
                this.form.purchasedAt = true;
            } else {
                this.form.purchasedAt = `${this.year || ""}-${this.month || ""}`
            }
        },
    }
};
</script>