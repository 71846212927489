var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "유형"
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "기업",
      "value": "기업"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "개인",
      "value": "개인"
    }
  })], 1)], 1), _c('template', {
    slot: "구입시기"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.years,
      "placeholder": "년도",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.ispurchase
    },
    model: {
      value: _vm.year,
      callback: function ($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.months,
      "placeholder": "월",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.ispurchase
    },
    model: {
      value: _vm.month,
      callback: function ($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-4px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "구입 시기 잘 모름",
      "hide-details": ""
    },
    model: {
      value: _vm.ispurchase,
      callback: function ($$v) {
        _vm.ispurchase = $$v;
      },
      expression: "ispurchase"
    }
  })], 1)], 1)], 1), _c('template', {
    slot: "구입한 대리점"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.cities,
      "placeholder": "시/도",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.isStore
    },
    model: {
      value: _vm.city,
      callback: function ($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.districts[_vm.city],
      "placeholder": "구/군",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.isStore
    },
    model: {
      value: _vm.district,
      callback: function ($$v) {
        _vm.district = $$v;
      },
      expression: "district"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "대리점을 선택하세요.",
      "outlined": "",
      "hide-details": "",
      "disabled": _vm.isStore
    }
  })], 1), _c('v-col', {
    staticClass: "mt-4px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "구입 대리점 잘 모름",
      "hide-details": ""
    },
    model: {
      value: _vm.isStore,
      callback: function ($$v) {
        _vm.isStore = $$v;
      },
      expression: "isStore"
    }
  })], 1)], 1)], 1)], 2), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('btn-primary', _vm._b({
    on: {
      "click": _vm.save
    }
  }, 'btn-primary', {
    disabled: _vm.disabled
  }, false), [_vm._v("문의하기")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }